import React, { Suspense, lazy } from "react";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Routing
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingSpinner from "../components/Loader/LoadingSpinner";
import ScrollToTop from "../hooks/useScrollTop";
import ScrollToTopButton from "../components/Buttons/ScrollToTopButton";

// Lazy-loaded Pages
const MapsPage = lazy(() => import("./MapsPage"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./TermsOfUse"));
const Subscription = lazy(() => import("./Subscription"));
const RentARobot = lazy(() => import("./RentARobot"));
const Serveus911 = lazy(() => import("./Serveus911"));
const TrainARobot = lazy(() => import("./TrainARobot"));
// const ConnectAndCollaborate = lazy(() => import("./ConnectAndCollaborate"));
const MarketPlace = lazy(() => import("./MarketPlace"));

export default function Landing() {
  return (
    <div className="vh-100 ">
      <Router>
        <ScrollToTop />
        <TopNavbar />
        <Suspense fallback={<LoadingSpinner />}>
            <div className="white_smoke">
          <Routes>
            <Route path="/" element={<MapsPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/cards" element={<Subscription />} />
            <Route path="/rent-a-robot" element={<RentARobot />} />
            <Route path="/Serveus911" element={<Serveus911 />} />
            <Route path="/work-buddy" element={<TrainARobot />} />
            {/* <Route
              path="/connect-and-collaborate"
              element={<ConnectAndCollaborate />}
            /> */}
            <Route
              path="/marketplace"
              element={<MarketPlace />}
            />
          </Routes>
            </div>
        </Suspense>
        <ScrollToTopButton />
        <Footer />
      </Router>
    </div>
  );
}