import * as React from "react";
import serveuslogoWhite from "../img/serveus_white.png";
import serveuslogo from "../img/serveuslogo.png";

function SvgComponent({ variant,isSideBar }) {
  return (
    <img
      src={variant === "black" ? serveuslogo : serveuslogoWhite}
      alt="logo"
      className={variant === "black" ? "w-100 h-100": isSideBar ? 'sidebar_logo':  "main_logo"}
    />
  );
}

export default SvgComponent;